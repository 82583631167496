import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "LeftContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createStaticVNode(" 网站建设中... <br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739><br data-v-1a539739> 网站建设中...<br data-v-1a539739>", 69)),
    _createElementVNode("span", {
      id: "tempICPInfo",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toBeian && _ctx.toBeian(...args)))
    }, "蜀ICP备2024067019号-2")
  ]))
}