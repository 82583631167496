import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Background = _resolveComponent("Background")!
  const _component_Topbar = _resolveComponent("Topbar")!
  const _component_LeftContent = _resolveComponent("LeftContent")!
  const _component_RightContent = _resolveComponent("RightContent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Background),
    _createVNode(_component_Topbar),
    _createVNode(_component_LeftContent),
    _createVNode(_component_RightContent)
  ]))
}