<template>
    <div class="RightContent">
        网站建设中...
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
        网站建设中...
    </div>
</template>


<script lang="ts">

import { EventBus } from '@/eventBus';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'RightContent',
    data(): {
        backgroundMode: string,
        backgroundModeListener: any
    } {
        return {
            backgroundMode: 'White',
            backgroundModeListener: null
        }
    },
    created() {
        this.backgroundModeListener = EventBus.on('changeBackgroundModeEvent', (message) => {
            console.log(message);
        });
    },
    unmounted() {
        if (this.backgroundModeListener !== null) {
            EventBus.off('changeBackgroundModeEvent', this.backgroundModeListener);
            this.backgroundModeListener = null;
        }
    },
    methods: {

    }
})

</script>


<style scoped>

.RightContent {
    position: absolute;
    top: 5rem;
    left: 30%;
    right: 0%;
    border: 1px solid #000000;
    z-index: 10;
}

</style>