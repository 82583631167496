<template>
    <div class="LeftContent">
        网站建设中...
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
        网站建设中...<br>
        <span id="tempICPInfo" @click="toBeian">蜀ICP备2024067019号-2</span>
    </div>
</template>


<script lang="ts">

import { EventBus } from '@/eventBus';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LeftContent',
    data(): {
        backgroundMode: string,
        backgroundModeListener: any
    } {
        return {
            backgroundMode: 'White',
            backgroundModeListener: null
        }
    },
    created() {
        this.backgroundModeListener = EventBus.on('changeBackgroundModeEvent', (message) => {
            console.log(message);
        });
    },
    unmounted() {
        if (this.backgroundModeListener !== null) {
            EventBus.off('changeBackgroundModeEvent', this.backgroundModeListener);
            this.backgroundModeListener = null;
        }
    },
    methods: {
        toBeian() {
            window.open('https://beian.miit.gov.cn/', '_blank');
        }
    }
})

</script>


<style scoped>

.LeftContent {
    position: absolute;
    top: 5rem;
    left: 0%;
    width: 30%;
    border: 1px solid #000000;
    z-index: 10;
}

#tempICPInfo {
    text-decoration: underline;
    cursor: pointer;
    color: rgba(20, 20, 20, 0.9);
}

#tempICPInfo:hover {
    color: #000000;
}
</style>