<template>
    <div class="Background"></div>
</template>


<script lang="ts">

import { EventBus } from '@/eventBus';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Background',
    data(): {
        backgroundMode: string,
        backgroundModeListener: any
    } {
        return {
            backgroundMode: 'White',
            backgroundModeListener: null
        }
    },
    created() {
        this.backgroundModeListener = EventBus.on('changeBackgroundModeEvent', (message) => {
            console.log(message);
        });
    },
    unmounted() {
        if (this.backgroundModeListener !== null) {
            EventBus.off('changeBackgroundModeEvent', this.backgroundModeListener);
            this.backgroundModeListener = null;
        }
    },
    methods: {

    }
})

</script>


<style scoped>

.Background {
    position: fixed;
    top: 0%;
    left: 0%;
    bottom: 0%;
    right: 0%;
    background-color: antiquewhite;
    z-index: 0;
}

</style>