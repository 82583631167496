import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "Topbar" }
const _hoisted_2 = { class: "userInfo" }
const _hoisted_3 = { class: "userInfoContainer" }
const _hoisted_4 = { class: "loginInfo" }
const _hoisted_5 = {
  key: 0,
  class: "no-login"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "TopbarBackground" }, null, -1)),
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "logo" }, null, -1)),
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "search" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "userIcon" }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          (!_ctx.isLogin)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", {
                  class: "no-login-login",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toLogin('Login')))
                }, _cache[2] || (_cache[2] = [
                  _createElementVNode("span", null, "登录", -1)
                ])),
                _createElementVNode("div", {
                  class: "no-login-register",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toLogin('Register')))
                }, _cache[3] || (_cache[3] = [
                  _createElementVNode("span", null, "注册", -1)
                ]))
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}