<template>
    <div class="Topbar">
        <div class="TopbarBackground">

        </div>
        <div class="logo">

        </div>
        <div class="search">

        </div>
        <div class="userInfo">
            <div class="userInfoContainer">
                <div class="userIcon">

                </div>
                <div class="loginInfo">
                    <div class="no-login" v-if="!isLogin">
                        <div class="no-login-login" @click="toLogin('Login')">
                            <span>登录</span>
                        </div>
                        <div class="no-login-register" @click="toLogin('Register')">
                            <span>注册</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="ts">

import { EventBus } from '@/eventBus';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Topbar',
    data(): {
        backgroundMode: string,
        backgroundModeListener: any,
        isLogin: boolean
    } {
        return {
            backgroundMode: 'White',
            backgroundModeListener: null,
            isLogin: false
        }
    },
    created() {
        this.backgroundModeListener = EventBus.on('changeBackgroundModeEvent', (message) => {
            console.log(message);
        });
    },
    unmounted() {
        if (this.backgroundModeListener !== null) {
            EventBus.off('changeBackgroundModeEvent', this.backgroundModeListener);
            this.backgroundModeListener = null;
        }
    },
    methods: {
        toLogin(message: string) {
            if (message === 'Login') {
                location.href = 'https://blog.hanxu.fun/user/login/';
            } else if (message === 'Register') {
                location.href = 'https://blog.hanxu.fun/user/register/';
            }
        }
    }
})

</script>


<style scoped>

.Topbar {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    height: 5rem;
    border: 1px solid #000000;
    z-index: 10;
}

.userInfo {
    position: absolute;
    top: 0%;
    right: 0%;
    width: 30%;
    height: 3rem;
    border: 1px solid #000000;
}

.userInfoContainer {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    border: 1px solid #000000;
    width: 70%;
    height: 100%;
}

.userIcon {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    border: 2px solid #FFFFFF;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.no-login {
    position: absolute;
    top: 50%;
    left: calc(10% + 2rem);
    transform: translateY(-50%);
    width: calc(85% - 2rem);
    height: 2rem;
    .no-login-login {
        position: absolute;
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);
        width: 45%;
        height: 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(250, 250, 250, 0.8);
        cursor: pointer;
    }
    .no-login-register {
        position: absolute;
        top: 50%;
        left: 75%;
        transform: translate(-50%, -50%);
        width: 45%;
        height: 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(250, 250, 250, 0.8);
        cursor: pointer;
    }
}

.no-login-login:hover {
    background-color: rgb(255, 255, 255);
}

.no-login-register:hover {
    background-color: rgb(255, 255, 255);
}

</style>