<template>
  <div>
    <Background />
    <Topbar />
    <LeftContent />
    <RightContent />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Background from './components/Background.vue';
import Topbar from './components/Topbar.vue';
import LeftContent from './components/LeftContent.vue';
import RightContent from './components/RightContent.vue';
import { EventBus } from './eventBus';

export default defineComponent({
  name: 'App',
  components: {
    Background,
    Topbar,
    LeftContent,
    RightContent
  }
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
